<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>Usuários</h1>
      </div>
      <div class="col">
        <router-link to="usuarios/cadastro">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :data="usuarios"
          :columns="columns"
          :itemsPerPage="10">
          <template #column="{item, column}">
            <span v-if="column.field == 'actions'">
              <i class="fas fa-edit mr-3 btn btn-warning" title="Editar usuário" @click="editarItem(item.id)"></i>
              <i class="fas fa-trash-alt mr-3 btn btn-danger" title="Deletar usuário" @click="deletarItem(item)"></i>
            </span>
            <span v-else>
              {{item[column.field]}}
            </span>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from './../../../uicomponents/MyTable'
import usuarioRepository from './../../../services/api/usuarioRepository'
export default {
  name: 'Usuarios',
  components: {
    MyTable
  },
  data(){
    return {
      isLoading: false,
      columns: [
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Usuário',
          field: 'usuario',
          sortable: 'usuario'
        },
        {
          label: 'Email',
          field: 'email',
          sortable: ''
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      usuarios: []
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    editarItem (id) {
      this.$router.push({ name: 'UsuariosCadastro', query: { id: id } })
    },
    deletarItem (usuario) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover usuário',
        text: `Deseja desativar o usuário ${usuario.usuario}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarUsuario(usuario)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Registro desativado com sucesso!'
          }).then(() => {
            _this.fetch()
          })
          
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarUsuario (usuario) {
      this.isLoading = true
      usuarioRepository.deletarUsuario(usuario.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    fetch () {
      this.isLoading = true
      usuarioRepository.listarUsuarios().then(response => {
        this.usuarios = response.data['data']
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>

</style>