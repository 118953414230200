import Repository from './repository'

const resource = '/v1/configuracoes/usuarios'

export default {
  buscaUsuarioPorId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  listarUsuarios () {
    return Repository.get(`${resource}`)
  },
  salvarUsuario (dados) {
    return Repository.post(`${resource}`, dados)
  },
  atualizarUsuario (id, dados) {
    return Repository.put(`${resource}/${id}`, dados)
  },
  deletarUsuario (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
